import { AccessTime, Campaign, CampaignOutlined, FileCopy } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import React from 'react';

const AnnouncementDialouge = (props) => {
    const { data, open, setOpen, handleMarkAsRead } = props;
    const [fileDialog, setFileDialog] = React.useState(false);
    const [fileSrc, setFileSrc] = React.useState("");
    const theme = useTheme();
    const handleFileClose = () => {
        setFileDialog(false);
        setFileSrc("");
    }

    const showFile = (fileLink) => {
        setFileSrc(fileLink);
        setFileDialog(true);
    }




    const toLocalTime = (date) => {
        // return new Date(date).toLocaleTimeString();
        //remove seconds
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    return (
        <>
        <Dialog open={open} onClose={() => setOpen(false)} sx={{ "& .MuiDialog-paper": {
            borderRadius: "20px",
          }}} fullWidth>
            <DialogContent >
            <Typography variant="h6" sx={{display: "flex", alignItems: "center"}}>
                Announcements
                <Campaign sx={{marginLeft: "10px"}}/>

            </Typography>
            <Box>
                {data.map((item) => (
                     <Box
                     sx={{
                         display: "flex",
                         padding: "10px 20px",
                         borderRadius: "10px",
                         margin: "10px 0",
                         cursor: "pointer",
                         backgroundColor: theme.palette.card.bg,
                         boxShadow: 'inset 0 0 0 1px #000000',
                         alignItems: "center"
                     }}
                 >
                        <Box>
                                <Typography sx={{ fontSize: "1.2rem" }}>
                                    {item.message}</Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", fontWeight: '1.2rem' }}>
                                    <AccessTime sx={{ marginRight: "5px", fontSize: "1rem" }} />
                                    {toLocalTime(item.createdAt)}
                                </Typography>
                                {item.createdBy && <Typography sx={{ display: "flex", alignItems: "center" }}>
                                    Created By: {item.createdBy}
                                </Typography>}
                                {item.fileLink &&
                                    <Box onClick={() => { showFile(item.fileLink) }} sx={{ display: "flex", alignItems: "center", width: "max-content", marginRight: "20px", backgroundColor: "#ffffff",color: theme.palette.text.secondary, borderRadius: "10px", padding: "5px" }}>
                                     View Attachment   <FileCopy  sx={{ marginLeft: "5px", cursor: "pointer", fontSize: "20px" }} />
                                    </Box>
                                }
                            </Box>
                         { !item.isRead ?
                         <Box sx={{   width: "max-content", marginLeft: "auto" }}>
                            <Button 
                            variant='outlined'  sx={{width: "max-content"}}
                            onClick={() => handleMarkAsRead(item.id)}
                            >Mark as Read</Button>
                        </Box>
                        :
                        null
                        }
                        
                    </Box>
                ))}
            </Box>
            </DialogContent>
        </Dialog>

        <Dialog open={fileDialog} onClose={handleFileClose} fullWidth 
        PaperProps={{
            style: {
              border: "1px solid #515151",
              borderRadius: "1rem",
            },
          }
            }>

        <DialogTitle>File</DialogTitle>
        <DialogContent>
            {
                fileSrc.split('.').pop() === "pdf" ?
                    <iframe src={`${fileSrc}#toolbar=0`} style={{ width: '100%', height: '500px' }} />
                    :
                    <img src={fileSrc} style={{ width: '100%', height: '500px' }} />
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleFileClose}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
    </>
    );
}

export default AnnouncementDialouge;
