import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../constants/userConstants";

export const login = (companyId, code) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    let kioskRequest = localStorage.getItem("kioskRequestData");
    

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // const { data } = await axios.post(
    //   `${process.env.REACT_APP_API_BACKEND}/api/kiosk/login`,
    //   { companyId: companyId, code: code },
    //   config
    // );
    let data;
    if (kioskRequest) {
      //get kiosk request id from local storage
      kioskRequest = JSON.parse(kioskRequest);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/login`,
        { companyId: companyId, code: code, kioskRequestId: kioskRequest.id },
        config
      );
      data = response.data;
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/login`,
        { companyId: companyId, code: code },
        config
      );
      data = response.data;
    }
    
      
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("companyInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    console.log(error.response.data.message);

  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("companyInfo");
  dispatch({ type: USER_LOGOUT });
};

export const register = ( email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "https://cms.herokuapp.com/api/register",
      { email: email, password: password },
      config
    );
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // });

    // localStorage.setItem("companyInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    console.log(error.response.data.message);
  }
};