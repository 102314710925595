import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material';
export default function ShiftSuccessPopup(props) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setDialogueOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.dialogueOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xs'
        PaperProps={{
          style: {
            backgroundColor: theme.palette.dialog.paper,
            borderRadius: "1rem",
          },
        }}


      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent component={"span"} className='dialogue-border-radius'>
          <DialogContentText component={"span"} id="alert-dialog-description" style={{color:"#0f870f",fontSize:"1.2rem", display:"flex",justifyContent:"center",alignItems:"center"}}>
          <DoneIcon  className="svg_icons" /><div>&emsp;{props.dialogueMessage}</div>
          </DialogContentText>
          <div 
          style={{marginTop:"2rem",fontSize:"1.2rem",paddingLeft:"1rem",color:theme.palette.text.secondary, textAlign:"center"}}>
            &emsp;Current time: <strong>{new Date().toLocaleTimeString()}</strong>
            </div>
        </DialogContent>
        <DialogActions style={{margin:"auto", paddingBottom:"2rem"}}>

          <Button disableElevation onClick={handleClose} variant='contained' autoFocus 
          style={{padding:"0.5rem 4.5rem", 
          textTransform:"capitalize",
          fontSize:"1.2rem",
          lineHeight:"1.5rem",
          fontWeight:"bold",
          backgroundColor:theme.palette.button.grey,
          color:theme.palette.text.secondary,
          }}>
            Close 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
