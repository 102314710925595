import React, { useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

function ApprovalDialog(props) {
  const { open, requestSent, setRequestSent, statusCode } = props;
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { companyInfo } = userLogin;  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${companyInfo.JWT_TOKEN}`
  }};

  
  
  const handleApproval = () => {
    if (name === '') {
      handleNameShakeAmiation();
      setError('Please enter your name');
      return;
    }

      
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/request-activation`, {name}, config)
    .then((res) => {
      if (res.status === 200) {
        setSuccess('Request sent successfully');
        setName('');
      }
      setTimeout(() => {
        setRequestSent(true);
        window.location.reload();
      }
      , 3000);

    })
    .catch((err) => {
      console.log(err);
    });

  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleNameShakeAmiation = () => {
    for (let i = 0; i < 2; i++) {
      setTimeout(() => {
        document.getElementById('applier-name').style.marginLeft = '1.5rem';
      }, 125 * i);
      setTimeout(() => {
        document.getElementById('applier-name').style.marginLeft = '1rem';
      }, 125 * i + 50);
    }

    };

    const handleCheckAgain = () => {
      const storedLastCheckTime = sessionStorage.getItem('lastCheckTime');
      const currentTime = Date.now();
      const elapsedTimeSinceLastCheck = storedLastCheckTime ? currentTime - parseInt(storedLastCheckTime) : Infinity;
    
      // Check if 30 seconds have elapsed since the last check
      if (elapsedTimeSinceLastCheck >= 60000) {
        // Update the last check time in session storage
        sessionStorage.setItem('lastCheckTime', currentTime.toString());
    
        // Perform the check status action here
        // For now, you can just reload the page
        window.location.reload();
      } else {
        // Display a message indicating that the check is throttled
        const timeLeft = Math.ceil((60000 - elapsedTimeSinceLastCheck) / 1000);
        setError(`Please wait ${timeLeft} seconds before checking again`);
      }
    };
    



  const theme = useTheme();
  return (
    <div>
    <Snackbar
    open={error !== '' ? true : false}
    autoHideDuration={6000}
    onClose={() => setError('')}
    message="Please enter your name"
     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="error">
        {error}
      </Alert>
    </Snackbar>
    <Snackbar
    open={success !== '' ? true : false}
    autoHideDuration={6000}
    onClose={() => setSuccess('')}
    message="Request sent successfully"
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="success" >
        {success}
      </Alert>
    </Snackbar>


      <Dialog open={open}
      //fullScreen
      sx={{backgroundColor: theme.palette.card.bg}}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.card.bg,
          boxShadow: 'none',
          borderRadius: '20px',
        },
      }}
    
      >
        <DialogTitle
        sx={
          statusCode === "rejected" ? {backgroundColor: theme.palette.button.red} : {backgroundColor: theme.palette.button.blue}
        }
        >
          {/* Request Device Activation */}
          {statusCode === "rejected" && 'Device Activation Rejected'}
          {statusCode === "pending" && 'Device Activation Pending'}
          {statusCode === "noname" && 'Device Activation Required'}
        </DialogTitle>
        <DialogContent>
         {statusCode !== 'rejected' ?  
         (<div>
          { !requestSent ?
            (<div style={{padding: '3rem 1rem'}}>
        <Typography  style={{color: theme.palette.text.primary, fontSize: '2rem', textAlign: 'center'}}>
        You need to get this Kiosk Device approved from the admin.
        </Typography>
          <input
          placeholder='Enter your name'
          value={name}
          onChange={handleChange}
            style={{
                width: '100%',
                padding: '1.2rem',
                backgroundColor: theme.palette.card.bg,
                color: theme.palette.text.primary,
                border: '1px solid #eeeeee',
                borderRadius: '20px',
                fontSize: '1.2rem',
                marginTop: '1.5rem',
            }}
            autoFocus
            />
            <span id="applier-name" style={{color: theme.palette.text.primary, marginLeft: '1rem', fontSize: '1rem'}}>
                *Please enter your name
            </span>
          
          <button
            onClick={handleApproval}
            style={{
              backgroundColor: theme.palette.button.blue,
              color: theme.palette.text.primary,
              padding: '1rem',
              border: 'none',
              borderRadius: '20px',
              width: '100%',
              fontSize: '1.2rem',
              marginTop: '1.5rem',
            }}
            >
            Request Approval
            </button>
            </div>)
            :
            (
            <div style={{padding: '3rem 1rem'}}>
              <Typography  style={{color: theme.palette.text.primary, fontSize: '2rem', textAlign: 'center'}}>
              ✅Your request has been sent successfully, please wait for the please wait for the authorized person to approve it.
              </Typography>
              <button
              onClick={handleCheckAgain}
              style={{
                backgroundColor: theme.palette.button.blue,
                color: theme.palette.text.primary,
                padding: '1rem',
                border: 'none',
                borderRadius: '20px',
                width: '100%',
                fontSize: '1.2rem',
                marginTop: '1.5rem',
              }}
            >
            Check Status
            </button>
            </div>
            )
          }
          </div>
         )
          :
            (<div style={{padding: '3rem 1rem'}}>
              <Typography  style={{color: theme.palette.text.primary, fontSize: '2rem', textAlign: 'center'}}>
              ❌Your request has been rejected by the authorized person.
              </Typography>
            </div>
          )}

        </DialogContent>
      </Dialog>
      </div>
  );
}

export default ApprovalDialog;
