import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";

export default function BreakEndDialogue(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <div>
      <Button
        variant="contained"
        style={{
          borderRadius: "20%",
          width: "6rem",
          height: "4.5rem",
          textTransform: "capitalize",
          fontSize: "1.2rem",
          padding: "1rem 1rem",
          lineHeight: "1.5rem",
          fontWeight: "bold",
          backgroundColor: "#ff9800",
          color: theme.palette.text.primary,
          margin: "0 1rem",
        }}
        onClick={handleClickOpen}
      >
        End Break
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.dialog.paper,
            borderRadius: "1rem",
          },
        }
        }
        
      >
        <DialogTitle style={{ textAlign: "center" , color: theme.palette.text.secondary, fontSize: "1.5rem"}} id="alert-dialog-title">
          Do you want to end a break?
        </DialogTitle>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            style={{
              marginLeft: "1rem",
              marginBottom: "1rem",
              backgroundColor: theme.palette.button.grey,
              color: "black",
              fontWeight: "bold",
              fontSize: "1.2rem",
              padding: "0.4rem 1.5rem",
            }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              marginRight: "1rem",
              marginBottom: "1rem",
              fontWeight: "bold",
              fontSize: "1.2rem",
              padding: "0.4rem 1.5rem",
            }}
            onClick={props.endBreak}
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
