import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";

import React from "react";

// custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 16px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

function Tasks() {
  const classes = tableStyles();
  return (
    <div>
      0 tasks for today
      <Paper
        sx={{
          marginTop: "1rem",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <TableContainer sx={{ width: "100%", overflowX: "hidden" }}>
          <Table sx={{ minWidth: "90vw" }} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Task #
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", width: "25%", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Title
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Assigned to
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Created by
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Mobile
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Category
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600", fontSize: "1rem" }}
                  className={classes.tableCell}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default Tasks;
