import React, { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import { Alert, Snackbar, Typography, useTheme } from "@mui/material";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import "./pin.css";



const PinAuth = (employee) => {
  const [pin, setPin] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const theme = useTheme();


  const PinBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  });
  
  const PinDigit = styled('div')({
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    border: '1px solid #dddddd',
    fontSize: '24px',
    fontWeight: 'bold',
    transition: 'border 0.3s, box-shadow 0.3s',
    boxShadow: '0 0 10px #0000007a',
    
    '&.filled': {
      backgroundColor: '#e4e4e4ff',
      color: '#000000',
      border: `1px solid ${severity === 'error' ? 'red' : theme.palette.primary.main}`,
    },
    '&.active': {
      border: `3px solid ${theme.palette.primary.main}`, 
    },
    '&:hover': {
      cursor: 'pointer',
    },
  });





  const handlePin = (e) => {
    setPin(e.target.value);
  };
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [shake, setShake] = useState(false);
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { companyInfo } = userLogin;
  const clearPin = () => {
    setPin("");
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${companyInfo.JWT_TOKEN}`,
    },
  };
  
  const authenticate = (pin) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/${employee.employee.id}/login`,
        { pin: pin },
        config
      )
      .then((res) => {
        setEmployeeInfo(res);
        sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
        setOpen(true);
        setSeverity("success");
        setMessage("Successfully Authenticated");
        setTimeout(() => {
          history.push(`/dashboard/${employee.employee.id}`);
        }, 1000);
      })
      .catch((err) => {
        setOpen(true);
        setSeverity("error");
        setShake(true);

        if (err.response && err.response.data && err.response.data.message && err.response.data.message.length > 0) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Something went wrong, please try again later.");
        }

        setTimeout(() => {
          setOpen(false);
          setShake(false);
          setMessage("");
          setSeverity("success");
          setPin("");
        }, 2000);
      });
  };
  const addPin = (value) => {
    if (pin.length < 5) {
      let newPin = pin + value;
      setPin(newPin);
    }
  };
  if (pin.length === 5) {
    authenticate(pin);
  }


  const deletePin = () => {
    let newPin = pin.slice(0, -1);
    setPin(newPin);
  };


  const getAvatarName = (employee) => {
    if(employee.employeeDetail) {
    if(employee.employeeDetail.fname && employee.employeeDetail.lname) {
      return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
    }
    else if(employee.employeeDetail.fname) {
      return employee.employeeDetail.fname.charAt(0).toUpperCase();
    }
    else if(employee.employeeDetail.lname) {
      return employee.employeeDetail.lname.charAt(0).toUpperCase();
    }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }
  }

  const getEmployeeName = (employee) => {
    if(employee.employeeDetail.fname && employee.employeeDetail.lname) {
      return employee.employeeDetail.fname + " " + employee.employeeDetail.lname;
    }
    else if(employee.employeeDetail.fname) {
      return employee.employeeDetail.fname;
    }
    else if(employee.employeeDetail.lname) {
      return employee.employeeDetail.lname;
    }
    else if(employee.email) {
      return employee.email;
    }
    return "Unknown";
  }

  
  const pinRef = useRef(null);
const handleFocus = () => {
     if (pinRef.current) {
       pinRef.current.focus();
     }
  };


  return (
    <div style={{margin: "0 10px 0 10px"}}>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={8}>
           <Avatar sx={{color: "text.primary", width: 70, height: 70, margin: "auto", fontSize: "2rem", backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d"}}>
            {getAvatarName(employee.employee)}
           </Avatar>
     
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ textAlign: "center", margin: "auto" }}>
            {getEmployeeName(employee.employee)}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sx={{margin: "auto"}}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: "400", margin: "auto" }}
            className="employee-pin-enter"
          >
            Enter your Employee Code
          </Typography>
        </Grid> */}
        <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
          <form autoComplete="off">
          <PinBox className={shake && pin.length === 5 ? 'shake' : ''}>
            <PinDigit className={pin.length >= 1 ? 'filled' : pin.length === 0 ? 'active' : ''}>{pin[0]}</PinDigit>
            <PinDigit className={pin.length >= 2 ? 'filled' : pin.length === 1 ? 'active' : ''}>{pin[1]}</PinDigit>
            <PinDigit className={pin.length >= 3 ? 'filled' : pin.length === 2 ? 'active' : ''}>{pin[2]}</PinDigit>
            <PinDigit className={pin.length >= 4 ? 'filled' : pin.length === 3 ? 'active' : ''}>{pin[3]}</PinDigit>
            <PinDigit className={pin.length >= 5 ? 'filled' : pin.length === 4 ? 'active' : ''}>{pin[4]}</PinDigit>
          </PinBox>


</form>
<div style={{marginTop: "20px"}}>
          <div className="btnContainer">
            <Button
              onClick={() => {
                addPin("1");
              }}
              className="btn"
            >
              1
            </Button>
            <Button
              onClick={() => {
                addPin("2");
              }}
              className="btn"
            >
              2
            </Button>
            <Button
              onClick={() => {
                addPin("3");
              }}
              className="btn"
            >
              3
            </Button>
          </div>
          <div className="btnContainer">
            <Button
              className="btn"
              onClick={() => {
                addPin("4");
              }}
            >
              4
            </Button>
            <Button
              className="btn"
              onClick={() => {
                addPin("5");
              }}
            >
              5
            </Button>
            <Button
              className="btn"
              onClick={() => {
                addPin("6");
              }}
            >
              6
            </Button>
          </div>
          <div className="btnContainer">
            <Button
              className="btn"
              onClick={() => {
                addPin("7");
              }}
            >
              7
            </Button>
            <Button
              className="btn"
              onClick={() => {
                addPin("8");
              }}
            >
              8
            </Button>
            <Button
              className="btn"
              onClick={() => {
                addPin("9");
              }}
            >
              9
            </Button>
          </div>
          <div className="btnContainer">
            <Button
              onClick={clearPin}
              className="btn"
              style={{ textTransform: "capitalize", fontSize: "0.8rem !important" }}
              
            >
              Clear
            </Button>
            <Button
              className="btn"
              onClick={() => {
                addPin("0");
              }}
            >
              0
            </Button>
            <Button
              className="btn"
              onClick={() => {
                deletePin();
              }}
            >
              <BackspaceOutlinedIcon />
            </Button>
          </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        sx={{ marginTop: "45px" }}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={clearPin}
        open={open}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>

      
    </div>
  );
};

export default PinAuth;
