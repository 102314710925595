import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WebcamCapture from "../components/CameraCapture";
import "./cameraStyles.css";
import Webcam from "react-webcam";
import axios from "axios";
import { CircularProgress, useTheme } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  facingMode: "user",
};

function StartShift(props) {
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [loading, setLoading] = useState(false);
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  });
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
    setImage("");
    setCountdown(5);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }, 5000);
  };
  const handleRetake = () => {
    setImage("");
    setCountdown(5);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }, 5000);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCountdown = () => {
    setCountdown(countdown - 1);
    if (countdown == 0) {
      setCountdown(5);
    }
  };
  const employeeInfo = sessionStorage.getItem("employeeInfo")
    ? JSON.parse(sessionStorage.getItem("employeeInfo"))
    : null;

  const companyInfo = localStorage.getItem("companyInfo")
    ? JSON.parse(localStorage.getItem("companyInfo"))
    : null;


  const config = {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${employeeInfo.data.accessToken}`,
    },
  };

  // data url to blob
  const imagetoBlob = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  //upload image to server
  const handleConfirm = () => {
    setLoading(true);
    const imageBlob = imagetoBlob(image);
    const formData = new FormData();
    formData.append("StartShiftImage", imageBlob);
    formData.append("kioskId", companyInfo.sessionId);
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/startshift/${employeeInfo.data.employee.id}`,
        formData,
        config
      )
      .then((res) => {
        employeeInfo.data.employee.shiftStatus = "Working";
        employeeInfo.data.shift = res.data.shift;
        sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
        props.setDialogueMessage(res.data.message);
        props.setDialogueOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (open && countdown > 0) {
      const interval = setInterval(() => {
        handleCountdown();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, open]);

  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        style={{
          borderRadius: "20%",
          width: "6rem",
          height: "4.5rem",
          textTransform: "capitalize",
          fontSize: "1.2rem",
          padding: "1rem 1rem",
          lineHeight: "1.5rem",
          fontWeight: "bold",
        }}
        onClick={handleClickOpen}
      >
        Start Shift
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        style={{
          width: "100%",
          minWidth: "480px",
          maxWidth: "800px",
          height: "auto",
          margin: "auto",
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.dialog.paper,
            borderRadius: "1rem",
          },
        }
        }
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Please take a photo before starting your shift
        </DialogTitle>
        <DialogContent
          component={"span"}
          style={{
            margin: "auto",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <div className="webcam-container">
            {!image ? (
              <div>
                <div className="countdown">
                  {countdown > 1 ? countdown : <p>Smile :)</p>}
                </div>
                <Webcam
                  className="attendance-image"
                  audio={false}
                  height={"480px"}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={"640px"}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  mirrored={true}
                />
              </div>
            ) : (
              <img className="attendance-image" src={image} />
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div>
            <Button
              variant="contained"
              style={{ backgroundColor: theme.palette.button.grey, color: "#000", fontWeight: "bold" }}
              onClick={handleClose}
              className="cam-buttons"
            >
              Cancel
            </Button>
          </div>
          <div>
            {countdown == 0 && (
              <Button
                style={{ marginRight: "1rem", backgroundColor: theme.palette.button.blue, color: "#000", fontWeight: "bold" }}
                className="cam-buttons"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleRetake();
                }}
              >
                Retake
              </Button>
            )}
            {image ? (
              <>
                {loading ? (
                  <Button
                    variant="contained"
                    autoFocus
                    className="cam-buttons"
                    disabled
                  >
                    <CircularProgress style={{ color: theme.palette.text.primary }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleConfirm}
                    autoFocus
                    className="cam-buttons"
                    style={{ fontWeight: "bold" }}
                  >
                    <span>Confirm</span>
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                onClick={handleConfirm}
                autoFocus
                className="cam-buttons"
                // style={{ backgroundColor: "rgb(26, 35, 126)" }}
                disabled
              >
                Confirm
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StartShift;
