import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { MapContainer, TileLayer, Circle, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Ensure marker icons work properly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const OutOfRadiusDialog = ({ open, onClose, currLat, currLong, allowedLat, allowedLong, allowedRadius, distance }) => {
    // console.log(currLat, currLong, allowedLat, allowedLong, allowedRadius);
    //zoom level based on distance
    //map lat long is center of curr lat long and allowed lat long
    const mapCenterLat = (currLat + allowedLat) / 2;
    const mapCenterLong = (currLong + allowedLong) / 2;
    let zoomLevel = Math.round(15 - Math.log2(distance / 1000));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Location Details</DialogTitle>
            <DialogContent>
                <div style={{ height: '400px', width: '100%' }}>
                    <MapContainer center={[mapCenterLat, mapCenterLong]} zoom={zoomLevel} style={{ height: '100%', width: '100%' }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {/* Display current location marker */}
                        <Marker position={[currLat, currLong]}>
                            <Popup>Your Current Location</Popup>
                        </Marker>

                        {/* Display allowed area circle */}
                        <Circle center={[allowedLat, allowedLong]} radius={allowedRadius} color="blue" fillOpacity={0.2}>
                            <Popup>Allowed Area</Popup>
                        </Circle>
                        <Marker position={[allowedLat, allowedLong]}>
                            <Popup>Allowed Location</Popup>
                        </Marker>
                        
                    </MapContainer>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default OutOfRadiusDialog;
