import { Skeleton } from "@material-ui/lab";
import { Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import EmployeeScheduleTable from "../components/EmployeeScheduleTable";

const Schedule = () => {
const [data, setData] = useState([]);
const [loading, setLoading] = useState(true);
const employeeInfo  = (sessionStorage.getItem("employeeInfo") !== null) ? JSON.parse(sessionStorage.getItem("employeeInfo")) : null;
const employeeId = employeeInfo.data.employee.id;

const fetchSchedule = () => {
    setLoading(true);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${employeeInfo.data.accessToken}`,
        },
    };
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/schedule/${employeeId}`, config)
    .then((res) => {
        res.data.employeeSchedules.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        setData(res.data);
        setLoading(false);
    })
    .catch((err) => {
        console.log(err)
    })
}

useEffect(() => {
    fetchSchedule();
}
, []);

    return (
        <div>
       
                {loading ?
                (<Skeleton variant="rect"  height={118} />)
                :
                (data && data.employeeSchedules &&
                    <div style={{overflowY:"scroll", height:"70vh"}}>
                   <EmployeeScheduleTable employeeSchedules={data.employeeSchedules} />
                   </div>
                   )
                }
        </div>
    );

};

export default Schedule;

