import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Welcome from "../components/Welcome";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TimelineIcon from "@mui/icons-material/Timeline";
import Shift from "../dashboard tabs/Shift";
import Footer from "../components/Footer";
import Tasks from "../dashboard tabs/Tasks";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Avatar, useTheme } from "@mui/material";
import EmployeeShiftCalender from "../dashboard tabs/EmployeeShiftCalender";
import { AnnouncementRounded, CalendarMonth } from "@mui/icons-material";
import Schedule from "../dashboard tabs/Schedule";
import AnnouncementDialouge from "../components/AnnouncementDialouge";
import Announcements from "../dashboard tabs/Announcements";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Dashboard() {
  const [shiftStatus, setShiftStatus] = useState("inactive");
  const [employee, setEmployee] = useState([]);
  const [showAllTabs, setShowAllTabs] = useState(false);
  const [notificationDialouge, setNotificationDialouge] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  // const [employeeInfo, setEmployeeInfo] = useState(null);

  let params = useParams();
  const id = params.id;
  const navigate = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { companyInfo } = userLogin;
  const history = useHistory();
  const [value, setValue] = useState(0);
  const employeeInfo = sessionStorage.getItem("employeeInfo")
    ? JSON.parse(sessionStorage.getItem("employeeInfo"))
    : null;

  const date = new Date();

  if (!employeeInfo) {
    history.push("/");
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const employeeSignOut = () => {
    sessionStorage.removeItem("employeeInfo");
    history.push("/");
  };

  const checkNotification = () => {
    const announcements = employeeInfo.data.announcements;
    if (announcements) {
      const newAnnouncements = announcements.filter((item) => item.isRead === false);
      if (newAnnouncements.length > 0) {
        setAnnouncements(newAnnouncements);
        setNotificationDialouge(true);
      }
    }
  }
  const config = {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${employeeInfo.data.accessToken}`,
    },
  };

  const handleMarkAsRead = (id) => {
    //set isRead to true in session storage
    const announcements = employeeInfo.data.announcements;
    const newAnnouncements = announcements.map((item) => {
      if (item.id === id) {
        item.isRead = true;
      }
      return item;
    }
    )
    employeeInfo.data.announcements = newAnnouncements;
    setAnnouncements(newAnnouncements);
    sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/announcement/markasread/${id}/${employeeInfo.data.employee.id}`, {}, config)
      .then((res) => {
        console.log("Marked as read");
      }
      )
      .catch((err) => {
        console.log(err);
      }
      )
    //if all announcements are read, close the dialouge
    const unreadAnnouncements = newAnnouncements.filter((item) => item.isRead === false);
    if (unreadAnnouncements.length === 0) {
      setNotificationDialouge(false);
    }

  }

  const getAvatarName = (employee) => {
    if (employee.employeeDetail) {
      if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.fname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.lname) {
        return employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }

    return "Unknown";

  }


  const getEmployeeName = (employee) => {
    if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
      return employee.employeeDetail.fname + " " + employee.employeeDetail.lname;
    }
    else if (employee.employeeDetail.fname) {
      return employee.employeeDetail.fname;
    }
    else if (employee.employeeDetail.lname) {
      return employee.employeeDetail.lname;
    }
    else if (employee.email) {
      return employee.email;
    }
    return "Unknown";
  }

  useEffect(() => {

    const restrictedBranches = [
      'PMK Cobram',
      'PMK Warragul',
      'PMK Deniliquin',
      'PMK Yarrawonga',
      'PMK Ararat',
      'PMK Kilmore',
      'PMK Wallan',
      'PMK Maryborough',
      'PMK Drouin',
      'PMK Lakes Entrance',
      'Liquor Stax Roxy',
      'PMK Salisbury',
      'PMK Smithfield',
      'PMK Portland',
      'Liquor Stax Epping',
      'Bendigo Warehouse'
    ];
    
    if (restrictedBranches.includes(companyInfo.branchName)) {
      setShowAllTabs(false);
    }
    else {
      setShowAllTabs(true);
    }
    checkNotification();
  }, []);
  const theme = useTheme();
  return (
    <div style={{ marginTop: "5rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 2rem",
        }}
      >
        <div className="name" style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{
            width: "3.5rem", height: "3.5rem", marginRight: "1rem", color: "#fff", fontWeight: "500", fontSize: "2rem",
            backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d74"
          }}>

            {getAvatarName(employeeInfo.data.employee)}
          </Avatar>


          <h1>
            {getEmployeeName(employeeInfo.data.employee)}
          </h1>
        </div>
        <div className="date" style={{ display: "flex", alignItems: "center" }}>
          <h1>
            {date.toDateString()}
          </h1>
          <Button
            variant="contained"
            style={{
              height: "2.5rem",
              minWidth: "max-content",
              marginLeft: "1.5rem",
              fontWeight: "500",
              textTransform: "capitalize",
              fontSize: "0.9rem",
            }}
            onClick={employeeSignOut}
            startIcon={<ExitToAppIcon />}
          >
            Sign Out
          </Button>
        </div>
      </div>
      <Box>
        <Box sx={{ width: "98%", margin: "auto" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="secondary tabs example"
            sx={{
              height: "4rem",
              boxShadow: "inset 0px -5px 0px 0px rgba(0,0,0,0.75)"
            }}
            TabIndicatorProps={
              {
                style: {
                  backgroundColor: theme.palette.primary.main,
                  height: "0.3rem",
                  borderRadius: "0.5rem 0.5rem 0 0",
                  marginTop: "0.5rem",

                }
              }
            }

          >
            <Tab
              icon={<TimerOutlinedIcon />}
              iconPosition="start"
              label="Shift"
              {...a11yProps(0)}
              sx={{
                textTransform: "capitalize",
                paddingRight: "25px",
                color: theme.palette.text.primary
              }}
            />
            {showAllTabs &&
              <Tab
                icon={<TimelineIcon />}
                iconPosition="start"
                label="Timeline"
                {...a11yProps(1)}
                sx={{
                  textTransform: "capitalize",
                  paddingRight: "25px",
                  color: theme.palette.text.primary
                }}
              />
            }
            {showAllTabs &&
              <Tab
                icon={<CalendarMonth />}
                iconPosition="start"
                label="Schedule"
                {...a11yProps(2)}
                sx={{
                  textTransform: "capitalize",
                  paddingRight: "25px",
                  color: theme.palette.text.primary
                }}
              />
            }
            {showAllTabs &&
              <Tab
                icon={<AnnouncementRounded />}
                iconPosition="start"
                label="Announcements"
                {...a11yProps(3)}
                sx={{
                  textTransform: "capitalize",
                  color: theme.palette.text.primary
                }}
              />
            }
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Shift shiftStatus={shiftStatus} setShiftStatus={setShiftStatus} />
        </TabPanel>
        {showAllTabs &&
          <TabPanel value={value} index={1}>
            <EmployeeShiftCalender />
          </TabPanel>
        }
        {showAllTabs &&
          <TabPanel value={value} index={2}>
            <Schedule />
          </TabPanel>
        }
        {showAllTabs &&
          <TabPanel value={value} index={3}>
            <Announcements handleMarkAsRead={handleMarkAsRead} />
          </TabPanel>
        }
      </Box>
      <Footer />
      <AnnouncementDialouge data={announcements} open={notificationDialouge} setOpen={setNotificationDialouge} handleMarkAsRead={handleMarkAsRead} />
    </div>
  );
}

export default Dashboard;
