import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WebcamCapture from "../components/CameraCapture";
import "./cameraStyles.css";
import Webcam from "react-webcam";
import CircularProgress from "@mui/material/CircularProgress";
import BreakEndDialogue from "./BreakEndDialogue";
import axios from "axios";
import { useTheme } from "@mui/material";
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  facingMode: "user",
};
function OngoingBreak(props) {
  const [open, setOpen] = React.useState(false);
  const [countdown, setCountdown] = useState(5);
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  });
  const theme = useTheme();
  const employeeInfo = sessionStorage.getItem("employeeInfo")
    ? JSON.parse(sessionStorage.getItem("employeeInfo"))
    : null;
  const companyInfo = localStorage.getItem("companyInfo")
    ? JSON.parse(localStorage.getItem("companyInfo"))
    : null;

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${employeeInfo.data.accessToken}`,
    },
  };

  const endBreak = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/endbreak/${employeeInfo.data.employee.id}`,
        {
          kioskId: companyInfo.sessionId,
        },
        config
      )
      .then((res) => {
        employeeInfo.data.employee.shiftStatus = "Working";
        employeeInfo.data.shift = res.data.shift;
        sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
        props.setDialogueMessage(res.data.message);
        props.setDialogueOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // data url to blob
  const imagetoBlob = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  //upload image to server
  const handleConfirm = () => {
    setLoading(true);
    const imageBlob = imagetoBlob(image);
    const formData = new FormData();

    formData.append("EndShiftImage", imageBlob);
    formData.append("kioskId", companyInfo.sessionId);
    console.log(formData);
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/kiosk/endshift/${employeeInfo.data.employee.id}`,
        formData,
        config
      )
      .then((res) => {
        employeeInfo.data.employee.shiftStatus = "Not Working";
        employeeInfo.data.shift = "N/A";
        sessionStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
        props.setDialogueMessage(res.data.message);
        props.setDialogueOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
    setImage("");
    setCountdown(5);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }, 5000);
  };
  const handleRetake = () => {
    setImage("");
    setCountdown(5);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }, 5000);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCountdown = () => {
    setCountdown(countdown - 1);
    if (countdown == 0) {
      setCountdown(5);
    }
  };

  useEffect(() => {
    if (open && countdown > 0) {
      const interval = setInterval(() => {
        handleCountdown();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, open]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BreakEndDialogue endBreak={endBreak} />
        <Button
          variant="contained"
          style={{
            borderRadius: "20%",
            width: "6rem",
            height: "4.5rem",
            textTransform: "capitalize",
            fontSize: "1.2rem",
            padding: "1rem 1rem",
            lineHeight: "1.5rem",
            fontWeight: "bold",
            backgroundColor: theme.palette.button.red,
            color: theme.palette.text.primary,
            margin: "0 1rem",
          }}
          onClick={handleClickOpen}
        >
          End  Shift
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        style={{
          width: "100%",
          minWidth: "480px",
          maxWidth: "800px",
          height: "auto",
          margin: "auto",
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.dialog.paper,
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Please take a photo before ending your shift
        </DialogTitle>
        <DialogContent
          component={"span"}
          style={{
            margin: "auto",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <div className="webcam-container">
            {!image ? (
              <div>
                <div className="countdown">{countdown}</div>
                <Webcam
                  className="attendance-image"
                  audio={false}
                  height={"480px"}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={"640px"}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  mirrored={true}
                />
              </div>
            ) : (
              <img className="attendance-image" src={image} />
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div>
            <Button
              variant="contained"
              style={{ backgroundColor: "#e0e0e0", color: "#000" }}
              onClick={handleClose}
              className="cam-buttons"
            >
              Cancel
            </Button>
            {/* {image ? (
              <Button
                variant="contained"
                style={{ backgroundColor: "#e0e0e0", color: "#000" }}
                onClick={handleClose}
                className="cam-buttons"
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="contained"
                // style={{ backgroundColor: "#e0e0e0", color: "#000" }}
                onClick={handleClose}
                className="cam-buttons"
                disabled
              >
                Cancel
              </Button>
            )} */}
          </div>
          <div>
            {countdown == 0 && (
              <Button
                style={{ marginRight: "1rem", backgroundColor: theme.palette.button.blue, color: "#000", fontWeight: "bold" }}
                className="cam-buttons"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleRetake();
                }}
              >
                Retake
              </Button>
            )}
            {image ? (
              <Button
                variant="contained"
                onClick={handleConfirm}
                autoFocus
                className="cam-buttons"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  <span>Confirm</span>
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleConfirm}
                autoFocus
                className="cam-buttons"
                disabled
              >
                Confirm
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OngoingBreak;
