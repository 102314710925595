import React from 'react';
import { Typography } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';

const Welcome = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <br></br>
            <Typography variant="h3" sx={{ fontWeight: "700" }} className='icon'>Welcome</Typography>
            <br></br>
                <AccountCircleOutlined className="icon" sx={{ fontSize: "100px", borderRadius: "50%" }} />
            <br></br>
            <Typography variant="h5" >Please select employee in the list to start</Typography>
        </div>
    );
};

export default Welcome;   