import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import WebcamCapture from "../components/CameraCapture";
import StartShift from "../components/StartShift";
import OngoingShift from "../components/OngoingShift";
import OngoingBreak from "../components/OngoingBreak";
import CurrentTime from "../components/CurrentTime";
import { useSelector } from "react-redux";
import ShiftSuccessPopup from "../components/ShiftSuccessPopup";
import Grid from "@mui/material/Grid";
function Shift(props) {
  const [currentTime, setcurrentTime] = useState("");
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState("");
  let hours;
  let minutes;
  let shiftLength;
  const employeeInfo = sessionStorage.getItem("employeeInfo")
    ? JSON.parse(sessionStorage.getItem("employeeInfo"))
    : null;
  const shiftInfo = employeeInfo.data.shift;
  const breakInfo = employeeInfo.data.shift.break;

  const calculateDifference = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diff = end.getTime() - start.getTime();
    const hours = Math.floor(diff / 3.6e6);
    const minutes = Math.floor((diff % 3.6e6) / 6e4);
    return String(hours)+ " hours " + String(minutes)+ " minutes";
  }
  if (shiftInfo) {
  shiftLength = calculateDifference(shiftInfo.startTime, new Date());
  }




  // if (shiftInfo) {
  //   const startShift = new Date(shiftInfo.startTime);
  //   const currentTime = new Date();
  //   let diff = currentTime.getTime() - startShift.getTime();
  //   hours = Math.floor(diff / 3.6e6); 
  //   minutes = Math.floor((diff % 3.6e6) / 6e4);
  // }

  return (
    <div style={{ width: "100%" }}>
      {employeeInfo.data.employee.shiftStatus != "Not Working" && (
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={6} md={6}  >
            <h3 style={{ textAlign: "center" }}>
              Start shift: {shiftLength}
            </h3>
       <div style={{textAlign:"center"}}>
            <img src={shiftInfo.startImage} style={{maxWidth:"200px",width:"100%",textAlign:"center",borderRadius:"5px"}} alt="start image"></img>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            {/* {breakInfo && <span> {breakInfo.map((break)=>{
              <h3 style={{ textAlign: "center" }}>
                {break.end!=""? <span>Had a break: {calculateDifference(break.start,break.end)} </span>:
                <span>Start break: {calculateDifference(break.start,newDate())} </span>}
              
            </h3>
            
            })} </span> } */}
            {/* map breaks and show with h3 tag */}
            {
              shiftInfo!=="N/A" && breakInfo && breakInfo.map((breaks, id)=>{
                return <h3 key={id} style={{ textAlign: "center" }}>
                {breaks.end != "" ? <span>Had a break: {calculateDifference(breaks.start,breaks.end)} </span>:
                <span>Start break: {calculateDifference(breaks.start,new Date())} </span>}
                  </h3>
            }
            
              )}
            
              

    
            
          </Grid>
        </Grid>
      )}
      <h1 style={{ textAlign: "center" }}>
        <CurrentTime />
      </h1>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <ShiftSuccessPopup
          dialogueOpen={dialogueOpen}
          setDialogueOpen={setDialogueOpen}
          dialogueMessage={dialogueMessage}
        />
        {employeeInfo.data.employee.shiftStatus == "Not Working" && (
          <StartShift
            dialogueOpen={dialogueOpen}
            setDialogueOpen={setDialogueOpen}
            setDialogueMessage={setDialogueMessage}
          />
        )}
        {employeeInfo.data.employee.shiftStatus == "Working" && (
          <OngoingShift
            dialogueOpen={dialogueOpen}
            setDialogueOpen={setDialogueOpen}
            setDialogueMessage={setDialogueMessage}
          />
        )}
        {employeeInfo.data.employee.shiftStatus == "On Break" && (
          <OngoingBreak
            dialogueOpen={dialogueOpen}
            setDialogueOpen={setDialogueOpen}
            setDialogueMessage={setDialogueMessage}
          />
        )}
        {
          employeeInfo.data.employee.shiftStatus == "Not Working" && employeeInfo.data.shift.status == "Active" && (
            <OngoingShift
            dialogueOpen={dialogueOpen}
            setDialogueOpen={setDialogueOpen}
            setDialogueMessage={setDialogueMessage}
          />
          )
        }
        {/* <StartShift dialogueOpen={dialogueOpen} setDialogueOpen={setDialogueOpen} setDialogueMessage={setDialogueMessage} /> */}
      </div>
    </div>
  );
}

export default Shift;
