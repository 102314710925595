import { createTheme } from "@mui/material";



const theme = createTheme({
    palette: {
        primary: {
        main: "#4effca"
        // main: "#4ef3ff"
        },
        secondary: {
        main: "#000000",
        },
        background: {
        default: "#363b43",
        paper: "#000000c1",
        alert: "#ff0000",
        },
        
        text: {
        primary: "#ffffff",
        secondary: "#000000",
        },
        card: {
            bg: "#292a2c",
            text: "#ffffff"
        },
        dialog: {
        primary: "#ffffff",
        secondary: "#000000",
        paper: "#ffffff",
        },
        button: {
        blue: "#4e95ff",
        red: "#ff0000",
        grey: "#d6d6d6",
        },

        
    },
    typography: {
        fontFamily: "Roboto",
    },
    });


export default theme;

