import "./App.css";
import Main from "./Pages/Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import Employee from "./Pages/Employee";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { CssBaseline } from "@mui/material";

const App = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, companyInfo } = userLogin;
  return (
    <div>
      <CssBaseline />
      <Router>
        <ProtectedRoutes path="/" component={Navbar} />
        <Switch>  
          <Route exact path="/login" component={Login} />
          <ProtectedRoutes exact path="/" component={Main} />
          <ProtectedRoutes exact path="/dashboard/:id" component={Dashboard} />
        </Switch>
        {/* <ProtectedRoutes path="/" component={Footer}/> */}
      </Router>
    </div>
  );
};

export default App;
