import React from "react";

const CurrentTime = () => {
  const [currentTime, setcurrentTime] = React.useState(
    new Date().toLocaleTimeString()
  );

  React.useEffect(() => {
    setInterval(() => {
      setcurrentTime(new Date().toLocaleTimeString());
    }, 1000);
  }, []);

  return <div>{currentTime}</div>;
};

export default CurrentTime;
