import React, { useEffect, useState } from "react";
import ShiftBox from "../components/ShiftBox";
import '../components/Shifts.css'
import axios from "axios";
import ShiftCalender from "../components/ShiftCalender";
import { Box, Typography, useTheme } from "@mui/material";
import { ExpandMore, PlayArrow } from "@mui/icons-material";
import { ClockLoader } from "react-spinners";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: "none",
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(90, 89, 89, 0)'
        : 'rgba(0, 0, 0, 0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

const EmployeeShiftCalender = () => {
    const [loading, setLoading] = useState(true);
    const [employeeData, setEmployeeData] = useState([]);
    const [shiftClusterByMonth, setShiftClusterByMonth] = useState([]);  
    const [expanded, setExpanded] = React.useState(false);  
    const employeeInfo  = (sessionStorage.getItem("employeeInfo") !== null) ? JSON.parse(sessionStorage.getItem("employeeInfo")) : null;
    const employeeId = employeeInfo.data.employee.id;
    const theme = useTheme();
    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        };
    const fetchTimeLine = () => {
        setLoading(true);
        let endDate = new Date();
        let startDate = new Date(new Date().setDate(new Date().getDate()-30));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${employeeInfo.data.accessToken}`,
            },
        };

      


        
        const data= {
            endDate: new Date().toISOString().split('T')[0],
            startDate: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]  
        }
        axios.post(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/shifts/${employeeId}`,data, config).then((res) => {
            setShiftClusterByMonth(res.data.shiftClusterByMonth);
          

            setEmployeeData(res.data);
            setTimeout(() => {
                setLoading(false);
            }
            , 200);
        }
        ).catch((err) => {
            console.log(err)
            setLoading(false);
        }   
        )

    }


    useEffect(() => {
        fetchTimeLine();
    }
    , []);

                
          
    return (
        <div style={{overflowY:"scroll", height:"70vh", paddingBottom: "100px"}}>
            {loading ? 
            <Box sx={{display:"flex", justifyContent:"center", height:"100%"}}>
                <ClockLoader color={theme.palette.primary.main} loading={loading} size={75} />
            </Box>
            : 
        (shiftClusterByMonth && employeeData && employeeData.user ?
           <>
                <h1>Shifts</h1>
               
                {
                    Object.keys(shiftClusterByMonth).map((key) => {
                        return (
                            <div key={key}>
                                {/* <h1>{key}</h1>
                                <ShiftCalender data={shiftClusterByMonth[key]} month={key.split(" ")[0]} year={key.split(" ")[1]} user={employeeData.user} /> */}
                                <Accordion sx={{backgroundColor:"#f5f5f500", width:"100%"}} expanded={expanded === key} onChange={handleExpand(key)}>
                                    <AccordionSummary expandIcon={<PlayArrow sx={{color: "#fff"}} />} aria-controls="panel1a-content" id="panel1a-header">
                                        
                                        <Typography sx={{
                                            backgroundColor: theme.palette.text.secondary,
                                            width: "max-content",
                                            borderRadius: "10px",
                                            padding: "5px",
                                        }}>{key}</Typography>
                                    </AccordionSummary>
                                    <Box sx={{ overflow:"auto"}}>
                                    <ShiftCalender data={shiftClusterByMonth[key]} month={key.split(" ")[0]} year={key.split(" ")[1]} user={employeeData.user} />
                                    </Box>
                                </Accordion>


                            </div>
                        )
                    }
                    )
                
                }
           </>
            :
            <p>No shifts found</p>
        )
}
        </div>
    );
}

export default EmployeeShiftCalender;