import React, { useEffect } from 'react';
import { Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import Login from '../Pages/Login';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
function ProtectedRoutes(props) {
  const location = useLocation();
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading} = userLogin;
  const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
  const currentPath = location.pathname;

  const getSessionId = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${companyInfo.JWT_TOKEN}`,
      },
    };
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/kiosk/getSessionId`, config)
      .then((res) => {
        console.log(res.data);
        //add sessionId to companyInfo
        companyInfo.sessionId = res.data.kioskSessionId;
        localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
      
      })
      .catch((err) => {
        console.log(err);
      });

  }
  useEffect(() => {
    if (!companyInfo) {
      history.push('/login')
    } else if (!companyInfo.sessionId) {
      getSessionId();
    }

  }, [companyInfo]);

  return companyInfo ? (
    <Route {...props} />
  ) : (
    <Redirect
      to="/login"
    />
  );
}

export default ProtectedRoutes;
