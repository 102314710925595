import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Link } from "@mui/material";
import { Box } from "@mui/material";
import { createTheme, useTheme } from '@mui/material/styles';
import ErrorAlerts from "../components/ErrorAlerts";
import SuccessAlerts from "../components/SucessAlerts";
import logo from "../crinitis-logo.png"
//LOGIN PAGE



const theme = createTheme({
    spacing: 8
});

const Copyright = () => (
    <Typography variant="body2"  align="center" style={{fontSize:"1.2rem",fontWeight:"600"}}>
      Copyright &#169; Crinits {new Date().getFullYear()}
    </Typography>
);

const Login = () =>{
  const [value, setValue] = useState("/login");
  const [companyId, setCompanyId,] = useState("");
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, companyInfo } = userLogin;
  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  useEffect(() => {
    if (companyInfo && companyInfo.JWT_TOKEN) {
      history.push('/');
    }
  }, [history, companyInfo, redirect]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!companyId) {
      setErrorMessage("Please provide a Company ID");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!code) {
      setErrorMessage("Please provide code");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      dispatch(login(companyId, code));
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  if(error || errorMessage){
    setTimeout(() => {
      setErrorMessage("");
    
    }, 3500);
  }

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

    return(
        <Box container component="main" sx={{height: "100vh"}}>
          <div
        style={{
          backgroundImage: "url(https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/loginkioskbg.jpeg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >

          </div>
      <Box sx={{paddingTop: "5px",backgroundColor: "#cac7c747", zIndex: 1000,maxWidth: 'max-content', height: 'max-content', margin: 'auto', borderRadius: '20px',
        position: "fixed",
        top: 0,
        left: "20px",
        right: "20px",
        bottom: 0,
        height: "max-content",
        backdropFilter: "blur(10px)",
        
    }} 
    className="login-container"
    >
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 70,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {error && <ErrorAlerts error={error} />}
        {errorMessage && <ErrorAlerts error={errorMessage} />}
      </div>
        <div style={{margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"}}>
      
      <img style={{ width: "12rem", height: "9rem", margin: "auto" }}
      src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/logo-white-vertical.png"
      alt="Crinits"/>

     <div style={{marginTop:"1.5rem",}}>
          <Typography component="h1" variant="h5" style={{fontWeight:"600"}} >
            Login to Kiosk
          </Typography>
          </div>
          <form style={{width: "100%", // Fix IE 11 issue.
             marginTop: theme.spacing(1)}}
              noValidate
              onSubmit={submitHandler}
            >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="companyid"
              label="Company ID"
              name="companyId"
              // autoComplete="email"
              color="primary"
              autoFocus
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              inputProps={{
                style: { color: '#fff' },
              }
            }
              onChange={(e) => setCompanyId(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              type="password"
              id="code"
              
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              onChange={(e) => setCode(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{textTransform:"capitalize", fontWeight:"600", margin: theme.spacing(3, 0, 2)}}
            >
              Log in
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2" style={{fontWeight:"600", fontSize:"1rem"}}>
                  Forgot  login details?
                </Link> */}
              </Grid>
             
            </Grid>
            <Box mt={5}>
           <Copyright />
            </Box>
          </form>
        </div>
      </Box>
    </Box>
  );
}

export default Login;